"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANDROID_CLIENT_TYPE = exports.IOS_CLIENT_TYPE = exports.LS_USER_APPROVALS_KEY = exports.CONSENT_MESSAGE_MAP = exports.CLOSE_MODAL_COPY = exports.CONSENT_TYPE = exports.CONSENT_TYPE_LAMBDA_INVOKE = exports.CONSENT_TYPE_COIN_TRANSFER = exports.CONSENT_TYPE_NFT_TRANSFER = exports.MAKE_FRAME_VISIBLE = exports.SDK_MESSAGE = exports.SIGN_IN_REQUEST = exports.CLOSE_FRAME = exports.MESSAGE = exports.FRAME_READY = exports.SIGN_REQUEST = exports.ERROR = exports.SUCCESS = exports.ID_CHANGE_REQUESTED = exports.API_SIGNATURE_NOT_YET_VALID = exports.AUTH_FAILED = exports.TOO_MANY_LOGIN_REQUESTS = exports.MAX_FAILED_LOGIN_ATTEMPTS = exports.REQUEST_CANCELLED = exports.APP_ID_REQUIRED = exports.APP_NOT_FOUND = exports.INVALID_PHONE = exports.INVALID_EMAIL = exports.INVALID_REASON = exports.INVALID_REQUEST = exports.USER_REQUEST_DENIED = exports.INVALID_CONSENT_TOKEN = exports.NO_TOKEN_FOUND = exports.SOMETHING_WENT_WRONG = exports.EXPIRED_TOKEN = exports.USER_CONSENT_DENIED = exports.QUEUED = exports.IS_SCREENSHOT_TEST = exports.PASSKEY_SIGN_FAILED = exports.USE_PASSKEY = exports.WALLET_APPROVAL_CHECK_ENABLED = exports.ENABLE_VISIBILITY_TRACKER = exports.METAKEEP_IDENTITY_PROVIDER_PREFIX = exports.RECOVERY_EMAIL_ENABLED = exports.SHOW_RESEND_AFTER = exports.MOBILE_REDIRECT_HOST = exports.X_TIMESTAMP_HEADER = exports.X_API_SIGNATURE_HEADER = exports.API_BACKEND_URL = exports.API_URL = void 0;
exports.DEFAULT_USER_WALLET_FONT = exports.CTA_BUTTON_STYLE_MAP = exports.TABLET_WIDTH_BREAKPOINT = exports.APPROVAL_STATUS_MAP = exports.REASON_STRING_TEMPLATE = exports.NETWORK_STATES = exports.X_APP_ID_HEADER = exports.X_ORIGIN_HEADER = exports.APP_VISIBLE = exports.APP_HIDDEN = exports.DARK_SKELETON_FG = exports.LIGHT_SKELETON_FG = exports.DARK_SKELETON_BG = exports.LIGHT_SKELETON_BG = exports.DEFAULT_DARK_THEME_BG_COLOR = exports.DEFAULT_LIGHT_THEME_BG_COLOR = exports.SYSTEM_THEME = exports.DARK_THEME = exports.LIGHT_THEME = exports.LS_APP_DATA_KEY = exports.SKIP_RECOVERY_TIMEOUT_KEY = exports.CLIENT_TYPE_MAP = exports.WEB_CLIENT_TYPE = void 0;
const API_VERSION = process.env.REACT_APP_API_VERSION; //Current API version
exports.API_URL = process.env.REACT_APP_API_BACKEND_URL || ""; //API URL
// CONSENT BACKEND URL
exports.API_BACKEND_URL = `${exports.API_URL}/${API_VERSION}`;
exports.X_API_SIGNATURE_HEADER = "X-Api-Signature";
exports.X_TIMESTAMP_HEADER = "X-Timestamp";
exports.MOBILE_REDIRECT_HOST = process.env.REACT_APP_MOBILE_REDIRECT_HOST;
exports.SHOW_RESEND_AFTER = process.env.REACT_APP_OTP_RESEND_DELAY_SECONDS;
exports.RECOVERY_EMAIL_ENABLED = Boolean(process.env.REACT_APP_ENABLE_RECOVERY_EMAIL);
exports.METAKEEP_IDENTITY_PROVIDER_PREFIX = process.env.REACT_APP_METAKEEP_IDENTITY_PROVIDER_PREFIX;
exports.ENABLE_VISIBILITY_TRACKER = Boolean(process.env.REACT_APP_VISIBILITY_TRACKER_ENABLED === "true");
exports.WALLET_APPROVAL_CHECK_ENABLED = Boolean(process.env.REACT_APP_ENABLE_WALLET_APPROVAL_CHECK);
exports.USE_PASSKEY = Boolean(process.env.REACT_APP_USE_PASSKEY === "true");
exports.PASSKEY_SIGN_FAILED = "PASSKEY_SIGN_FAILED";
exports.IS_SCREENSHOT_TEST = process.env.REACT_APP_ENV === "SCREENSHOT_TEST";
const CONSENT_STATUS_MAP = Object.freeze({
    QUEUED: "QUEUED",
    USER_CONSENT_DENIED: "USER_CONSENT_DENIED",
    EXPIRED_TOKEN: "EXPIRED_TOKEN",
    SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
    NO_TOKEN_FOUND: "NO_TOKEN_FOUND",
    INVALID_CONSENT_TOKEN: "INVALID_CONSENT_TOKEN",
    INVALID_REASON: "INVALID_REASON",
    USER_REQUEST_DENIED: "USER_REQUEST_DENIED",
    INVALID_REQUEST: "INVALID_REQUEST",
    INVALID_EMAIL: "INVALID_EMAIL",
    INVALID_PHONE: "INVALID_PHONE",
    APP_NOT_FOUND: "APP_NOT_FOUND",
    APP_ID_REQUIRED: "APP_ID_REQUIRED",
    REQUEST_CANCELLED: "REQUEST_CANCELLED",
    MAX_FAILED_LOGIN_ATTEMPTS: "MAX_FAILED_LOGIN_ATTEMPTS",
    TOO_MANY_LOGIN_REQUESTS: "TOO_MANY_LOGIN_REQUESTS",
    AUTH_FAILED: "AUTH_FAILED",
    API_SIGNATURE_NOT_YET_VALID: "API_SIGNATURE_NOT_YET_VALID",
    ID_CHANGE_REQUESTED: "ID_CHANGE_REQUESTED",
});
const FRAME_MESSAGE_EVENT = Object.freeze({
    SUCCESS: "Success",
    ERROR: "Error",
    SIGN_REQUEST: "SIGN_REQUEST",
    FRAME_READY: "FRAME_READY",
    MESSAGE: "message",
    CLOSE_FRAME: "CLOSE_FRAME",
    SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
    SDK_MESSAGE: "SDK_MESSAGE",
    MAKE_FRAME_VISIBLE: "MAKE_FRAME_VISIBLE",
});
exports.QUEUED = CONSENT_STATUS_MAP.QUEUED, exports.USER_CONSENT_DENIED = CONSENT_STATUS_MAP.USER_CONSENT_DENIED, exports.EXPIRED_TOKEN = CONSENT_STATUS_MAP.EXPIRED_TOKEN, exports.SOMETHING_WENT_WRONG = CONSENT_STATUS_MAP.SOMETHING_WENT_WRONG, exports.NO_TOKEN_FOUND = CONSENT_STATUS_MAP.NO_TOKEN_FOUND, exports.INVALID_CONSENT_TOKEN = CONSENT_STATUS_MAP.INVALID_CONSENT_TOKEN, exports.USER_REQUEST_DENIED = CONSENT_STATUS_MAP.USER_REQUEST_DENIED, exports.INVALID_REQUEST = CONSENT_STATUS_MAP.INVALID_REQUEST, exports.INVALID_REASON = CONSENT_STATUS_MAP.INVALID_REASON, exports.INVALID_EMAIL = CONSENT_STATUS_MAP.INVALID_EMAIL, exports.INVALID_PHONE = CONSENT_STATUS_MAP.INVALID_PHONE, exports.APP_NOT_FOUND = CONSENT_STATUS_MAP.APP_NOT_FOUND, exports.APP_ID_REQUIRED = CONSENT_STATUS_MAP.APP_ID_REQUIRED, exports.REQUEST_CANCELLED = CONSENT_STATUS_MAP.REQUEST_CANCELLED, exports.MAX_FAILED_LOGIN_ATTEMPTS = CONSENT_STATUS_MAP.MAX_FAILED_LOGIN_ATTEMPTS, exports.TOO_MANY_LOGIN_REQUESTS = CONSENT_STATUS_MAP.TOO_MANY_LOGIN_REQUESTS, exports.AUTH_FAILED = CONSENT_STATUS_MAP.AUTH_FAILED, exports.API_SIGNATURE_NOT_YET_VALID = CONSENT_STATUS_MAP.API_SIGNATURE_NOT_YET_VALID, exports.ID_CHANGE_REQUESTED = CONSENT_STATUS_MAP.ID_CHANGE_REQUESTED;
exports.SUCCESS = FRAME_MESSAGE_EVENT.SUCCESS, exports.ERROR = FRAME_MESSAGE_EVENT.ERROR, exports.SIGN_REQUEST = FRAME_MESSAGE_EVENT.SIGN_REQUEST, exports.FRAME_READY = FRAME_MESSAGE_EVENT.FRAME_READY, exports.MESSAGE = FRAME_MESSAGE_EVENT.MESSAGE, exports.CLOSE_FRAME = FRAME_MESSAGE_EVENT.CLOSE_FRAME, exports.SIGN_IN_REQUEST = FRAME_MESSAGE_EVENT.SIGN_IN_REQUEST, exports.SDK_MESSAGE = FRAME_MESSAGE_EVENT.SDK_MESSAGE, exports.MAKE_FRAME_VISIBLE = FRAME_MESSAGE_EVENT.MAKE_FRAME_VISIBLE;
exports.CONSENT_TYPE_NFT_TRANSFER = "NFT_TRANSFER";
exports.CONSENT_TYPE_COIN_TRANSFER = "COIN_TRANSFER";
exports.CONSENT_TYPE_LAMBDA_INVOKE = "LAMBDA_INVOKE";
exports.CONSENT_TYPE = {
    NFT_TRANSFER: exports.CONSENT_TYPE_NFT_TRANSFER,
    COIN_TRANSFER: exports.CONSENT_TYPE_COIN_TRANSFER,
    LAMBDA_INVOKE: exports.CONSENT_TYPE_LAMBDA_INVOKE,
};
exports.CLOSE_MODAL_COPY = "Are you sure you want to cancel?";
exports.CONSENT_MESSAGE_MAP = new Proxy({
    [exports.QUEUED]: "You may close this window",
    [exports.USER_CONSENT_DENIED]: "Operation cancelled",
    [exports.EXPIRED_TOKEN]: "Something went wrong",
    [exports.INVALID_CONSENT_TOKEN]: "Something went wrong",
    [exports.SOMETHING_WENT_WRONG]: "Something went wrong",
    [exports.NO_TOKEN_FOUND]: exports.NO_TOKEN_FOUND,
    [exports.USER_REQUEST_DENIED]: "Operation cancelled",
    [exports.SUCCESS]: "  ",
    [exports.INVALID_REQUEST]: "Something went wrong",
    [exports.CONSENT_TYPE.LAMBDA_INVOKE]: "Are you sure you want to cancel?",
    [exports.CONSENT_TYPE.NFT_TRANSFER]: "Are you sure you want to cancel?",
    [exports.CONSENT_TYPE.COIN_TRANSFER]: "Are you sure you want to cancel this transfer?",
    [exports.APP_NOT_FOUND]: "Invalid app id",
    [exports.APP_ID_REQUIRED]: "App id is required",
    [exports.REQUEST_CANCELLED]: "Operation has been cancelled",
    [exports.MAX_FAILED_LOGIN_ATTEMPTS]: "Max failed login attempts reached, please try again in an hour",
    [exports.TOO_MANY_LOGIN_REQUESTS]: "Too many login attempts, please try again in an hour",
    [exports.API_SIGNATURE_NOT_YET_VALID]: "It looks like your device date and time settings are out of sync. This can cause issues connecting to our servers. Please check your device settings and try again.",
}, {
    get: (target, key) => target[key] || "Something went wrong",
});
exports.LS_USER_APPROVALS_KEY = "user_approvals";
exports.IOS_CLIENT_TYPE = "IOS";
exports.ANDROID_CLIENT_TYPE = "ANDROID";
exports.WEB_CLIENT_TYPE = "WEB";
exports.CLIENT_TYPE_MAP = {
    [exports.IOS_CLIENT_TYPE]: "ios",
    [exports.ANDROID_CLIENT_TYPE]: "android",
};
exports.SKIP_RECOVERY_TIMEOUT_KEY = "user_skip_recovery_timeout";
exports.LS_APP_DATA_KEY = "metakeep_app_data_info";
exports.LIGHT_THEME = "LIGHT";
exports.DARK_THEME = "DARK";
exports.SYSTEM_THEME = "AUTO";
exports.DEFAULT_LIGHT_THEME_BG_COLOR = "#333333"; // default btn color for light theme (secondary color)
exports.DEFAULT_DARK_THEME_BG_COLOR = "#026CDF"; // default btn color for dark theme
exports.LIGHT_SKELETON_BG = "#f3f3f3";
exports.DARK_SKELETON_BG = "#323140";
exports.LIGHT_SKELETON_FG = "#ecebeb";
exports.DARK_SKELETON_FG = "#5F5E70";
exports.APP_HIDDEN = "hidden";
exports.APP_VISIBLE = "visible";
exports.X_ORIGIN_HEADER = "x-referer-origin";
exports.X_APP_ID_HEADER = "x-app-id";
exports.NETWORK_STATES = {
    PENDING: "pending",
    IDLE: "idle",
    FAILED: "failed",
};
exports.REASON_STRING_TEMPLATE = {
    RAW_MESSAGE: "RAW_MESSAGE",
    BRAND_PERMISSION_MESSAGE: "BRAND_PERMISSION_MESSAGE",
};
exports.APPROVAL_STATUS_MAP = {
    APPROVED: "APPROVED",
    UNKOWN_STATUS: "UNKOWN_STATUS",
};
exports.TABLET_WIDTH_BREAKPOINT = 500;
exports.CTA_BUTTON_STYLE_MAP = {
    YES_NO: "YES_NO",
    ALLOW: "ALLOW",
};
exports.DEFAULT_USER_WALLET_FONT = "INTER";
