import { CLOSE_FRAME } from "../../constants";
import { shallow } from "zustand/shallow";

import { useStore } from "../../store";
import { postMessage } from "../../utils";
import clx from "classnames";
import { CLOSE_BUTTON_CSS_SELECTOR } from "../../utils/cssUtils";

export const CloseFrame = () => {
  const {
    frameState,
    setCloseModalState,
    loading,
    showApprovalBanner,
    customCss,
  } = useStore(
    (state) => ({
      frameState: state.frameState,
      setCloseModalState: state.setCloseModalState,
      loading: state.loading,
      showApprovalBanner: state.showApprovalBanner,
      customCss: state.customCss,
    }),
    shallow
  );

  // Custom CSS for the Close "X" button to hide it
  const closeButtonCss = customCss?.[CLOSE_BUTTON_CSS_SELECTOR] || {};
  const customCloseButtonDisplay = closeButtonCss["display"];
  const customCloseButtonStyle = {
    ...(customCloseButtonDisplay ? { display: customCloseButtonDisplay } : {}),
  };

  const onCloseFrame = () => {
    postMessage(CLOSE_FRAME);
  };

  const onClick = () => {
    if (frameState) onCloseFrame();
    else setCloseModalState(true);
  };
  if (loading) return null;
  return (
    <div
      data-testid="close-frame"
      id="close_frame"
      onClick={onClick}
      className={clx("close-frame", showApprovalBanner && "with-banner")}
      style={customCloseButtonStyle}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_256_685)">
          <rect width="24" height="24" rx="12" fill="#F9F9F9" />
          <path
            d="M16 8L8 16M8 8L16 16"
            stroke="#333333"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_256_685">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
