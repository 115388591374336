import cssescape from "cssesc";

export const addImportant = (value) => `${value} !important`;

export const BUTTON_CSS_SELECTORS = {
  PRIMARY_BTN: ".primary-button",
  PRIMARY_BTN_HOVER: ".primary-button:hover",
  PRIMARY_BTN_OUTLINED: ".primary-button-outlined",
  PRIMARY_BTN_OUTLINED_HOVER: ".primary-button-outlined:hover",
};

export const FRAME_CSS_SELECTOR = ".frame-container";
export const INNER_FRAME_CSS_SELECTOR = ".inner-frame-container";
export const ROOT_CSS_SELECTOR = ".root";
export const LOGO_CSS_SELECTOR = ".logo";
export const OTP_TITLE_CSS_SELECTOR = ".otp-title";
export const OTP_SUBTITLE_CSS_SELECTOR = ".otp-subtitle";
export const OTP_HELPER_TEXT_CSS_SELECTOR = ".otp-helper-text";
export const CHANGE_BUTTON_CSS_SELECTOR = ".change-button";
export const CLOSE_BUTTON_CSS_SELECTOR = ".close-button";

// Inline styles don't support !important, skip adding important
// in these cases
export const INLINE_STYLE_SELECTORS = [
  LOGO_CSS_SELECTOR,
  OTP_TITLE_CSS_SELECTOR,
  OTP_SUBTITLE_CSS_SELECTOR,
  OTP_HELPER_TEXT_CSS_SELECTOR,
  CHANGE_BUTTON_CSS_SELECTOR,
  CLOSE_BUTTON_CSS_SELECTOR,
];

export const getParsedCustomCss = (cssObject = {}) => {
  const parsedCss = {};
  Object.keys(cssObject).forEach((key) => {
    if (key.startsWith("@media")) {
      parsedCss[key] = getParsedCustomCss(cssObject[key]);
    } else {
      parsedCss[key] = {};
      Object.keys(cssObject[key]).forEach((cssKey) => {
        parsedCss[key][cssKey] = INLINE_STYLE_SELECTORS.includes(key)
          ? cssescape(cssObject[key][cssKey])
          : addImportant(cssescape(cssObject[key][cssKey]));
      });
    }
  });

  return parsedCss;
};
